import {
  GroupType,
  PartnerSubtype,
  PartnerType,
  PublicationLevel,
  RoleType,
  SearchIndexContentTypes,
  VideoType,
} from 'generated/types'

import { FORMS_TRANSLATION_KEY } from 'components/form/config'
import { ASSET_DATA_TRANSLATION_KEY } from 'features/asset-data/config'
import { CORE_DATA_TRANSLATION_KEY } from 'features/core-data/config'
import { IMPORTER_TRANSLATION_KEY } from 'features/importer/config'
import { SEARCH_INDEX_TRANSLATION_KEY } from 'features/search-index/config'
import { DEFAULT_LOCALE } from 'utils/forms/forms'
import {
  FormActionFeedback,
  FormActionMessages,
  FormFieldLabels,
  FormHelpTexts,
  FormValidationMessages,
} from 'utils/forms/messages'
import { BackendBasicErrors, BackendPublicationErrors, BackendValidationErrors } from 'utils/graphql/errors'

import { getTranslationKeyFormatter } from './translation-key-formatter'

const getUnpublishRelationErrorMessage = (remoteTypePlural: string) =>
  `The item you are trying to unpublish is still used in ${remoteTypePlural}.\nRemove all associations between ${remoteTypePlural} and this item. Then try again.`

const getDeleteDraftRelationErrorMessage = (remoteTypePlural: string) =>
  `The draft you are trying to delete is still used in ${remoteTypePlural}.\nRemove all associations between ${remoteTypePlural} and this draft. Then try again.`

const imageSafeZoneText =
  'The pink areas are image safe-zones where ui-elements might overlap the image. Please make sure that important content is not placed in these areas.'
const teaserImageText =
  'A teaser image that will be used as header on detail pages and might be used in image sliders. Aspect Ratio: 16:9. Suggested Dimensions: 4096x2304px (4K), but please provide the maximum resolution you have available.'
const teaserSquareImageText =
  'Usually the same image as in teaser but cropped to the most interesting content. Used as header image on mobile detail-pages and might be used in sliders. Aspect Ratio: 1:1. Suggested Dimensions: 2000×2000px, but please provide the maximum resolution you have available.'

// ? might be a better idea to make these messages static
// ? we cannot use the translation-key-formatter from the page because of circular dependencies
// ? so this is redundant, still feels nicer
const importerStatusTk = getTranslationKeyFormatter('status_page')

export const translations = {
  [ASSET_DATA_TRANSLATION_KEY]: {},
  common: {
    [RoleType.Arrangement]: 'Arrangement',
    [RoleType.Author]: 'Author',
    [RoleType.ChorusMaster]: 'Chorus Master',
    [RoleType.Composer]: 'Composer',
    [RoleType.Conductor]: 'Conductor',
    [RoleType.Director]: 'Director',
    [RoleType.Engineer]: 'Engineer',
    [RoleType.InstrumentalSoloist]: 'Instrumental Soloist',
    [RoleType.Mixing]: 'Mixing',
    [RoleType.Orchestrator]: 'Orchestrator',
    [RoleType.Other]: 'Other',
    [RoleType.Production]: 'Production',
    [RoleType.VocalSoloist]: 'Vocal Soloist',

    search_field__approx_total_hits: 'approx. {{label}} Hits',
    search_field__total_hits_one: '{{count}} Hit',
    search_field__total_hits_other: '{{count}} Hits',
    search_field__total_hits_zero: 'No Hits',
  },
  // TODO: Not all these messages are in core-message. We should restructure validation-messages into more logic namespaces
  [CORE_DATA_TRANSLATION_KEY]: {
    [BackendBasicErrors.LiveConcertDraftVodExists]:
      'This live concert was already copied to a vod, you cannot create a draft. If you need to make urgent changes to the live concert, delete the vod-draft first.',
    [BackendBasicErrors.LiveConcertHasNotEnded]:
      'A VoD-Concert cannot be created before the last rerun of the Live-Concert concluded. If a Live-Concert has no reruns, the concerts end-time is relevant.',
    [BackendBasicErrors.LiveConcertIsDraft]:
      'Live Concert Drafts cannot be copied to into a VoD-Concert. Please publish the concert first and wait till its end-time elapsed.',
    [BackendBasicErrors.LiveConcertUnpublishedChanges]:
      'The Live-Concert you are trying to copy has unpublished changes. You will not be able to change the Live-Concert after copying it to a VoD-Concert. Please publish your changes and try again.',
    [BackendBasicErrors.NoDraftToDelete]:
      'The object has no draft that can be deleted. Please try to refresh the page to update a potentially stale publication status.',
    [BackendBasicErrors.NotFound]:
      'The asset you tried to alter could not be found. Please report this error to a developer.',
    [BackendBasicErrors.OperationUnsupported]: 'The operation you attempted is not supported',
    [BackendBasicErrors.VodConcertAlreadyExists]: 'A VodConcert connected to this LiveConcert already exists',
    [BackendPublicationErrors.AlbumTracksMissingStreams]:
      'Some of the album tracks are missing streams. Please trigger repackaging of the album streams and try again.',
    [BackendPublicationErrors.ArtistRoleMissing]: 'Every artist needs to have role. Please add one',
    [BackendPublicationErrors.ComposersMissing]:
      'A work must have at least one composer. Please assign one for this work and try publishing again.',
    [BackendPublicationErrors.DateOfBirthMissing]:
      'An artist with the role composer must have a date of birth. Please assign one and try publishing again.',
    [BackendPublicationErrors.DraftDeleteHasPublishedPerformanceWorks]:
      getDeleteDraftRelationErrorMessage('performance works'),
    [BackendPublicationErrors.DraftDeleteUsedInAlbums]: getDeleteDraftRelationErrorMessage('albums'),
    [BackendPublicationErrors.DraftDeleteUsedInComposedWorks]: getDeleteDraftRelationErrorMessage('composed works'),
    [BackendPublicationErrors.DraftDeleteUsedInConcerts]: getDeleteDraftRelationErrorMessage('concerts'),
    [BackendPublicationErrors.DraftDeleteUsedInConcertWorks]: getDeleteDraftRelationErrorMessage('concert works'),
    [BackendPublicationErrors.DraftDeleteUsedInPictures]: getDeleteDraftRelationErrorMessage('pictures'),
    [BackendPublicationErrors.DraftDeleteUsedInWork]: getDeleteDraftRelationErrorMessage('works'),
    [BackendPublicationErrors.DraftExistsUnpublishNotPossible]:
      'Please delete the draft before unpublishing. If the status is not shown as having a draft please reload the page.',
    [BackendPublicationErrors.EpochMissing]:
      'An artist with the role composer must have an epoch selected. Please assign one and try publishing again.',
    [BackendPublicationErrors.GenreMissing]:
      'A work must have at least one genre. Please assign one for this work and try publishing again.',
    [BackendPublicationErrors.EpochOnComposerMissing]:
      'At least one of the connected composers has no epoch. Please add an epoch and try again. A current version of your work was saved as draft.',
    [BackendPublicationErrors.IsrcNotUnique]: 'The used ISRC is not unique. Please use a different ISRC.',
    [BackendPublicationErrors.IsrcUsedInConcert]:
      'The ISRC "{{isrc}}" is already in use in concert "{{conflictingNodes}}". Please use a different ISRC.',
    [BackendPublicationErrors.IsrcUsedInPerformanceWork]:
      'The ISRC "{{isrc}}" is already in used in a performance work of concert "{{conflictingNodes}}". Please use a different ISRC.',
    [BackendPublicationErrors.IsrcUsedInVideo]:
      'The ISRC "{{isrc}}" is already in use in video "{{conflictingNodes}}". Please use a different ISRC.',
    [BackendPublicationErrors.PerformanceWorkMissing]:
      'No performance works connected to this concert. Please provide some and try again',
    [BackendPublicationErrors.PerformanceWorksStreamsMissing]:
      'One or more performance works have no streams. Please make sure that all performance works are connected to valid streams.',
    [BackendPublicationErrors.PerformanceWorkUnpublished]:
      'Concert could not be published because all connected Performance Works are unpublished. Please publish at least one performance work and try again.',
    [BackendPublicationErrors.SettingStreamPermissionsFailed]:
      'Stream permissions could not be set on performance works. Please try to publish the concert again.',
    [BackendPublicationErrors.SliderEmpty]: 'You cannot publish an empty slider. Please add some items.',
    [BackendPublicationErrors.StreamingEndTimeBeforeStart]:
      'Playout End Time cannot be before start time. Change start- or end-time.',
    [BackendPublicationErrors.StreamingInvalidChannel]:
      'Invalid Channel ID supplied. Check your settings or inform a developer.',
    [BackendPublicationErrors.StreamingInvalidIds]:
      'Invalid Playout IDs supplied. Probably a playout started while you started editing. Please reload and try again.',
    [BackendPublicationErrors.StreamingInvalidJobState]:
      'Tried to change a job that is not scheduled or running. Probably a playout changed its state while you started editing. Reload and try again.',
    [BackendPublicationErrors.UnpublishBannerUsedInContentContainer]:
      'Banner cannot be unpublished because it is still used as a content container in curated content or on a detailpage. Please remove it and try again.',
    [BackendPublicationErrors.UnpublishSliderUsedInContentContainer]:
      'Slider cannot be unpublished because it is still used as a content container in curated content or on a detailpage. Please remove it and try again.',
    [BackendPublicationErrors.UnpublishUsedInAlbums]: getUnpublishRelationErrorMessage('albums'),
    [BackendPublicationErrors.UnpublishUsedInArrangedWorks]: getUnpublishRelationErrorMessage('arranged works'),
    [BackendPublicationErrors.UnpublishUsedInArtists]: getUnpublishRelationErrorMessage('artists'),
    [BackendPublicationErrors.UnpublishUsedInComposedWorks]: getUnpublishRelationErrorMessage('composed works'),
    [BackendPublicationErrors.UnpublishUsedInComposers]: getUnpublishRelationErrorMessage('composers'),
    [BackendPublicationErrors.UnpublishUsedInConcerts]: getUnpublishRelationErrorMessage('concerts'),
    [BackendPublicationErrors.UnpublishUsedInConcertWorks]: getUnpublishRelationErrorMessage('concert works'),
    [BackendPublicationErrors.UnpublishUsedInCuratedSliderItem]:
      getUnpublishRelationErrorMessage('curated slider item'),
    [BackendPublicationErrors.UnpublishUsedInGroups]: getUnpublishRelationErrorMessage('groups'),
    [BackendPublicationErrors.UnpublishUsedInLibrettoedWorks]: getUnpublishRelationErrorMessage('librettoed works'),
    [BackendPublicationErrors.UnpublishUsedInMovements]: getUnpublishRelationErrorMessage('movements'),
    [BackendPublicationErrors.UnpublishUsedInPartners]: getUnpublishRelationErrorMessage('partners'),
    [BackendPublicationErrors.UnpublishUsedInPictures]: getUnpublishRelationErrorMessage('pictures'),
    [BackendPublicationErrors.UnpublishUsedInStageItem]: getUnpublishRelationErrorMessage('stage item'),
    [BackendPublicationErrors.UnpublishUsedInTracks]: getUnpublishRelationErrorMessage('tracks'),
    [BackendPublicationErrors.UnpublishUsedInVideo]: getUnpublishRelationErrorMessage('video'),
    [BackendPublicationErrors.UnpublishUsedInWorks]: getUnpublishRelationErrorMessage('works'),
    [BackendPublicationErrors.VodConcertExists]:
      'This live-concert already has a connected and published vod-concert. Publication is no longer possible because it could affect both entities.',
    [BackendPublicationErrors.WorksMissing]: 'A concert must have at least one work in order to be published.',

    [GroupType.Band]: 'Band',
    [GroupType.ChamberEnsemble]: 'Chamber Ensemble',
    [GroupType.ChamberOrchestra]: 'Chamber Orchestra',
    [GroupType.Choir]: 'Choir',
    [GroupType.Combo]: 'Combo',
    [GroupType.Duo]: 'Duo',
    [GroupType.Ensemble]: 'Ensemble',
    [GroupType.Octett]: 'Octett',
    [GroupType.Orchestra]: 'Orchestra',
    [GroupType.Quartet]: 'Quartet',
    [GroupType.Trio]: 'Trio',
    [GroupType.Various]: 'Various',

    [PartnerSubtype.ChamberMusicHall]: 'Chamber Music Hall',
    [PartnerSubtype.Club]: 'Club',
    [PartnerSubtype.ConcertHall]: 'Concert Hall',
    [PartnerSubtype.OpenAir]: 'Open Air',
    [PartnerSubtype.OperaHouse]: 'Opera House',
    [PartnerSubtype.Theatre]: 'Theatre',
    [PartnerType.Curator]: 'Curator',

    [PartnerType.Festival]: 'Festival',
    [PartnerType.FocusTopic]: 'Focus Topic',
    [PartnerType.ProductionCompany]: 'Production Company',
    [PartnerType.RecordingCompany]: 'Record Company',
    [PartnerType.Series]: 'Series',
    [PartnerType.Sponsor]: 'Sponsor',
    [PartnerType.Venue]: 'Venue',

    [VideoType.Documentary]: 'Documentary',
    [VideoType.Interview]: 'Interview',
    [VideoType.Short]: 'Short',
  },
  [FORMS_TRANSLATION_KEY]: {
    [BackendValidationErrors.BannerRequired]: 'You must provide an image for this banner before it can be published.',
    [BackendValidationErrors.CoverRequired]:
      'You must provide a cover image for this content before it can be published.',
    [BackendValidationErrors.DefaultTranslationMissing]:
      'Text for default language (en) must be provided if any other language is set. Please provide the english source text or remove translations for other languages.',
    [BackendValidationErrors.FieldRequired]:
      '{{field}} cannot be empty. You must provide a value before you can proceed.',
    [BackendValidationErrors.InputLength]: 'Value is too short. Please enter value of sufficient length.',
    [BackendValidationErrors.MaximumFieldLength]:
      'The text is longer than allowed. Please shorten it. Note that in a field with multiple languages the offending text might be in another language.',

    [BackendValidationErrors.NoStreamTrailerIsrc]:
      'You provided a trailer isrc for which no encoded asset exists. Either remove isrc or encode an asset for this ISRC to proceed.',
    [BackendValidationErrors.ProhibitedMarkdownElementUsed]:
      'Markdown contains prohibited element, allowed elements are: h2, h3, paragraph, link, list.',
    [BackendValidationErrors.PartnerMustHaveLogo]: 'You must provide a logo for a partner before publication.',
    [BackendValidationErrors.Subset]: 'One or more of the passed values are not an allowed value for this field.',
    [BackendValidationErrors.TeaserRequired]:
      'You must provide a teaser image for this content before it can be published.',
    [BackendValidationErrors.TeaserSquareRequired]:
      'You must provide a teaser square image for this content before it can be published.',
    [BackendValidationErrors.VodPerformanceWorkStreamRequired]:
      'Cannot publish performance work without stream. Please set an isrc that is connected to transcoded asset.',
    [FormActionFeedback.deletedDraft]: '{{type}}-Draft deleted.',
    [FormActionFeedback.published]: '{{type}} published.',
    [FormActionFeedback.publishFailed]: 'Publication failed. Please check all fields for errors.',
    [FormActionFeedback.savedDraft]: 'Saved {{type}}-Draft.',
    [FormActionFeedback.unpublished]: '{{type}} unpublished.',
    [FormActionMessages.cancel]: 'Cancel',
    [FormActionMessages.create]: 'Create {{type}}',
    [FormActionMessages.publish]: 'Publish',
    [FormActionMessages.save]: 'Save {{type}}',
    [FormActionMessages.saveDraft]: 'Save Draft',

    [FormFieldLabels.concertEndTime]: 'End Time (UTC)',
    [FormFieldLabels.concertStartTime]: 'Start Time (UTC)',
    [FormFieldLabels.concertStreamStartTime]: 'Stream Start Time (UTC)',
    [FormFieldLabels.courtesyCredit]: 'Courtesy Credit',
    [FormFieldLabels.cover]: 'Cover',
    [FormFieldLabels.createTitle]: 'New {{type}}',
    [FormFieldLabels.immersiveEnvironment]: 'Immersive Environment Image',
    [FormFieldLabels.teaser]: 'Teaser Image',
    [FormFieldLabels.teaserSquare]: 'Teaser Image Square',
    [FormHelpTexts.archivalReleaseDate]:
      'The date from which on forward the concert will be available in GMT/UTC+0. Your timezone is {{timezone}}, {{timezoneOffset, timezoneCalculation}}.',
    [FormHelpTexts.concertEndTime]:
      'The end time of the concert in GMT/UTC+0. Your timezone is {{timezone}}, {{timezoneOffset, timezoneCalculation}}.',
    [FormHelpTexts.concertImage]: `A photograph of the real concert if available, otherwise another suitable picture. Aspect Ratio: 16:9. Suggested Dimensions: 4096x2304px (4K), but please provide the maximum resolution you have available. ${imageSafeZoneText}`,
    [FormHelpTexts.concertImageSquare]: `Same as the concert image above but cropped to a square for mobile devices. Crop to the most important elements. Aspect Ratio: 1:1. Suggested Dimensions: 2000x2000px, but please provide the maximum resolution you have available. ${imageSafeZoneText}`,
    [FormHelpTexts.concertStartTime]:
      'The start time of the concert in GMT/UTC+0. Your timezone is {{timezone}}, {{timezoneOffset, timezoneCalculation}}.',
    [FormHelpTexts.concertStreamStartTime]:
      'The start of pre-programming in GMT/UTC+0. Your timezone is {{timezone}}, {{timezoneOffset, timezoneCalculation}}.',
    [FormHelpTexts.courtesyCredit]:
      'A courtesy credit usually given in the format "[artist] appears courtesy of [label]"',
    [FormHelpTexts.cover]:
      'The cover art used within the video player before a stream is available. Should have the same aspect-ratio as the video.',
    [FormHelpTexts.expandLocalizedInput]: 'Expand translations for this field by clicking on the language label "en".',
    [FormHelpTexts.featuredArtists]:
      'You can define all of the available roles for an artist, but in most contexts only vocal-/instrumental-soloists and conductors will be shown. For sliders ordering is only consistent within a group: Conductors will always be ordered last no matter where you put them in the list, but two consecutive soloists will be displayed consecutively. Artists will be shown, eg. in the header of a detailpage, artist-slider or performance work-details.',
    [FormHelpTexts.featuredGroups]:
      'Groups will be displayed in the order you define here, eg. in the header of a video detailpage or in the Artist-Slider after Soloists.',
    [FormHelpTexts.geoAccessCountries]:
      'A list of countries used to specify the availability of the video streams. This only controls the geoblocking for the streams itself, the visibility of the content is controlled by the clients.',
    [FormHelpTexts.geoAccessMode]:
      'The mode for geoblocking evaluation. Choose to either deny access from the specified countries or allow access from exactly the selected countries.',
    [FormHelpTexts.immersiveEnvironment]:
      'An image to display in the VisionOS immersive environment. Aspect Ratio: 2:1. Minimum resolution: 8192 x 4096px, Format: JPG/PNG, spherically projected.',
    [FormHelpTexts.markdownText]:
      'Textblock for SEO-Purposes. Allowed markdown syntax: H2 (##), H3 (###), Paragraphs (Line breaks), Links ([title](https://example.com)), Lists (-). No rich text-editor, please edit in an external editor and then paste here. More Info ',
    [FormHelpTexts.playoutEndTime]:
      'The end time of the playout in GMT/UTC+0. Your timezone is {{timezone}}, {{timezoneOffset, timezoneCalculation}}. ',
    [FormHelpTexts.playoutStartTime]:
      'The start time of the playout in GMT/UTC+0. Your timezone is {{timezone}}, {{timezoneOffset, timezoneCalculation}}. Stream is started 20 minutes earlier',
    [FormHelpTexts.publicationLevel]:
      'The publication level that is required to access this content. U1 – everyone / U2 – users that have an account / U3 – users that have an account and a ticket',
    [FormHelpTexts.streamUrl]:
      'The stream url for this asset. This is set by correlating the asset isrc with a transcoded stream. If there is no value yet then you either did not provide an isrc yet, no assets with this isrc could be found or transcoding is still in progress. Only updated when saving a draft, so if you just loaded the page and things do not look as expected, try saving a draft once.',
    [FormHelpTexts.takedownDate]:
      'The date until the concert will be available in GMT/UTC+0. Your timezone is {{timezone}}, {{timezoneOffset, timezoneCalculation}}.',

    [FormHelpTexts.teaserImage]: teaserImageText,
    [FormHelpTexts.teaserImageSafeZone]: `${teaserImageText} ${imageSafeZoneText}`,
    [FormHelpTexts.teaserSquareImage]: teaserSquareImageText,
    [FormHelpTexts.teaserSquareImageSafeZone]: `${teaserSquareImageText} ${imageSafeZoneText}`,
    [FormValidationMessages.artistMustHaveRole]: 'An artist must have a role, please select one.',
    [FormValidationMessages.concertMinOneWork]: 'A concert must have at least one work.',
    [FormValidationMessages.deathAfterBirthArtist]: `The date of the artist's death cannot be before his/her birth.`,
    [FormValidationMessages.durationNotZero]: 'Duration cannot be zero. Please chose another value.',

    [FormValidationMessages.imageUploadDefault]:
      'Sorry, something went wrong. We do not know what. Please retry the upload.',
    [FormValidationMessages.imageUploadLimitFilesize]:
      'The file is larger than 10MB. Please select a smaller file to continue.',

    [FormValidationMessages.imageUploadNoFile]: 'No file selected. Please select a file to continue.',
    [FormValidationMessages.imageUploadWrongAspectRatioDefault]:
      'This file does not have the correct aspect ratio. Please make sure that the images width and height conform to the required ratio.',
    [FormValidationMessages.imageUploadWrongAspectRatioHd]:
      'This file does not have the correct aspect ratio. Please make sure that the images width and height have a ratio of 16:9.',
    [FormValidationMessages.imageUploadWrongAspectRatioSquare]:
      'This file does not have the correct aspect ratio. Please make sure that the images width and height have a ratio of 1:1.',
    [FormValidationMessages.imageUploadWrongFileType]:
      'This file is not an image. Please select an image to continue (eg. jpg, png, gif).',
    [FormValidationMessages.isrcFormat]:
      'Invalid format for International Standard Recording Code. Example Value: DEF056730110. An ISRC consists of 12 character, starts with a two-letter Country Code, followed by a three letter issuer code, followed by the last two digits of the year in which it was first issued, followed by 5 digits.',
    [FormValidationMessages.mandatoryDefaultLanguage]: `If you provide any translation you also need to set the default language (${DEFAULT_LOCALE}).`,
    [FormValidationMessages.markFormat]:
      'Invalid format for mark/duration. Example Value: 01:40:20. A mark/duration consists of hours, minutes and seconds in the form hh:mm:ss',
    [FormValidationMessages.supplementaryTextInvalidHeadline]:
      'Only H2 or H3 are allowed (must start with ## or ###). Found level {{level}}',
    [FormValidationMessages.supplementaryTextNonParagraphAfterHeadline]:
      'Found headline without adjacent paragraph element. Make sure each headline is followed by one or more paragraphs.',
    [FormValidationMessages.supplementaryTextInvalidParagraphElement]:
      'Paragraphs are only allowed to contain text and links. Found element of type {{type}}',
    [FormValidationMessages.supplementaryTextStartWithHeadline]:
      'Text must start with an h2-headline. Please insert an h2-headline to continue',
    [FormValidationMessages.supplementaryTextInternalLinksMustBeRelative]:
      'Internal links must be relative (start with `/`). Please remove the preceding domain. Links will be turned into absolute links automatically on the frontend.',
    [FormValidationMessages.supplementaryTextInternalLinksMustNotIncludeLocale]:
      'Links must not start with a locale (eg. `/en/`). Please remove the locale as it will cause the users language preference to be disregarded.',
    [FormValidationMessages.referenceListArtistMinOne]: 'At least one artist must be selected',
    [FormValidationMessages.requiredField]: 'Field is required. Please specify a value.',
    [FormValidationMessages.rerunAllFieldsWhenAnyField]:
      'All fields must be set for rerun when you set one time. If you do not know all dates yet, then remove re-run and add it again later.',
    [FormValidationMessages.streamTimesNotConsecutive]:
      '{{ targetFieldName }} of {{ targetRun }} is not after {{otherFieldName}} of {{otherRun}}',
    [FormValidationMessages.takedownMustBeAfterReleaseDate]:
      'The takedown date cannot be before the release date. Change one of the two involved dates.',
    [FormValidationMessages.upcFormat]:
      'Invalid format for Universal Product Code. Example Value: 00028941336323. An UPC consists of 13 digits and usually starts with zeroes',
    [FormValidationMessages.yearFormat]: `Wrong format. Year must be an integer, eg. 1969'`,
    [PublicationLevel.AccountRequired]: 'U2 – user needs an account',
    [PublicationLevel.NoAuthentication]: 'U1 – everyone',
    [PublicationLevel.TicketRequired]: 'U3 – user needs an account and a ticket',
  },
  [IMPORTER_TRANSLATION_KEY]: {
    [importerStatusTk('assembling_releases')]: 'Assembling Releases',
    [importerStatusTk('completed')]: 'Import completed',
    [importerStatusTk('error')]: 'Import failed',
    [importerStatusTk('fetching_album_cover_urls')]: 'Fetching Album Covers from UMG API',
    [importerStatusTk('inserting_works')]: 'Processing Works',
    [importerStatusTk('reading_isrcs')]: 'Reading ISRCs',
    [importerStatusTk('reading_release_components')]: 'Reading Releases',
    [importerStatusTk('reading_release_contributors')]: 'Reading Contributors',
    [importerStatusTk('reading_releases')]: 'Reading Releases',
    [importerStatusTk('reading_resource_contributors')]: 'Reading Contributors',
    [importerStatusTk('reading_resource_works')]: 'Reading Works',
    [importerStatusTk('reading_resources')]: 'Reading Resources',
    [importerStatusTk('reading_session_locations')]: 'Reading Locations',
    [importerStatusTk('reading_track_groups')]: 'Reading Track Groups',
    [importerStatusTk('reading_tracks')]: 'Reading Tracks',
    [importerStatusTk('reading_worktree')]: 'Reading Works',
    [importerStatusTk('reading_worktree_contributors')]: 'Reading Contributors',
    [importerStatusTk('removing_unused_works')]: 'Removing unused works',
    [importerStatusTk('updating_admin_ui_index')]: 'Updating Records in Admin UI Search Index',
    [importerStatusTk('updating_audience_index')]: 'Updating Records in Audience Search Index',
    [importerStatusTk('updating_releases')]: 'Updating Releases',
    [importerStatusTk('updating_releases')]: 'Updating Releases',
    [importerStatusTk('updating_worktree')]: 'Updating Works',
    [importerStatusTk('updating_worktree_contributions')]: 'Updating Works',
    [importerStatusTk('writing_artists')]: 'Importing Artists',
    [importerStatusTk('writing_groups')]: 'Importing Ensembles',
    [importerStatusTk('writing_roles')]: 'Importing Roles',
  },
  [SEARCH_INDEX_TRANSLATION_KEY]: {
    [SearchIndexContentTypes.Albums]: 'Albums',
    [SearchIndexContentTypes.All]: 'Update all objects',
    [SearchIndexContentTypes.Artists]: 'Artists',
    [SearchIndexContentTypes.Banners]: 'Banners',
    [SearchIndexContentTypes.CuratedSliders]: 'Curated Slider',
    [SearchIndexContentTypes.Epochs]: 'Epochs',
    [SearchIndexContentTypes.Genres]: 'Genres',
    [SearchIndexContentTypes.Groups]: 'Groups',
    [SearchIndexContentTypes.LiveConcerts]: 'Live Concerts',
    [SearchIndexContentTypes.Partners]: 'Partners',
    [SearchIndexContentTypes.R2Works]: 'R2 Works',
    [SearchIndexContentTypes.Recreate]:
      'Recreate Index - This will cause search results to be empty for a time. Proceed with caution.',
    [SearchIndexContentTypes.Roles]: 'Roles',
    [SearchIndexContentTypes.Users]: 'Customers',
    [SearchIndexContentTypes.Videos]: 'Videos',
    [SearchIndexContentTypes.VodConcerts]: 'VoD Concerts',
    [SearchIndexContentTypes.Works]: 'Core Works',
  },
} as const
