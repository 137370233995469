// TODO: use translation-key-formatter for all of these

/**
 * Custom validations messages
 */
export enum FormValidationMessages {
  artistMustHaveRole = 'validation__reference_list_artist_must_have_role',
  concertMinOneWork = 'validation__concert_must_have_one_work',
  deathAfterBirthArtist = 'validation__death_after_birth_artist',
  durationNotZero = 'validation__duration_not_zero',
  imageUploadDefault = 'validation__image_upload_error_default',
  imageUploadLimitFilesize = 'validation__image_upload_error_limit_file_size',
  imageUploadNoFile = 'validation__image_upload_error_no_file',
  imageUploadWrongAspectRatioDefault = 'validation__image_upload_error_wrong_aspect_ratio_default',
  imageUploadWrongAspectRatioHd = 'validation__image_upload_error_wrong_aspect_ratio_hd',
  imageUploadWrongAspectRatioSquare = 'validation__image_upload_error_wrong_aspect_ratio_square',
  imageUploadWrongFileType = 'validation__image_upload_error_wrong_file_type',
  isrcFormat = 'validation__isrc_format',
  mandatoryDefaultLanguage = 'validation__default_language_mandatory',
  markFormat = 'validation__mark_format',
  referenceListArtistMinOne = 'validation__reference_list_min_one_artist',
  requiredField = 'validation__required_field',
  rerunAllFieldsWhenAnyField = 'validation__live_concert_rerun_all_fields_when_any',
  supplementaryTextStartWithHeadline = 'validation_supplementary_text_start_with_headline',
  supplementaryTextInvalidHeadline = 'validation_supplementary_text_invalid_headline',
  supplementaryTextNonParagraphAfterHeadline = 'validation_supplementary_text_non_paragraph_after_headline',
  supplementaryTextInvalidParagraphElement = 'validation_supplementary_text_invalid_paragraph_element',
  supplementaryTextInternalLinksMustBeRelative = 'validation_supplementary_text_internal_links_must_be_relative',
  supplementaryTextInternalLinksMustNotIncludeLocale = 'validation_supplementary_text_internal_links_must_not_include_locale',
  streamTimesNotConsecutive = 'validation__live_concert_stream_times_consecutive',
  takedownMustBeAfterReleaseDate = 'validation__vod_concert_takedown_after_release',
  upcFormat = 'validation__upc_format',
  yearFormat = 'validation__year_format',
}

/**
 * Field Labels that appear in more than one form and should appear consistent
 */
export enum FormFieldLabels {
  concertEndTime = 'label__concert_end_time',
  concertStartTime = 'label__concert_start_time',
  concertStreamStartTime = 'label__concert_stream_start_time',
  courtesyCredit = 'label__courtesy_credit',
  cover = 'label__cover',
  createTitle = 'title__new_document',
  immersiveEnvironment = 'label__immersive_environment',
  teaser = 'label__teaser',
  teaserSquare = 'label__teaser_square',
}

/**
 * Reusable help-texts for form-fields
 */
export enum FormHelpTexts {
  archivalReleaseDate = 'help__archival_release_date',
  concertEndTime = 'help__concert_end_time',
  concertImage = 'help__concert_image',
  concertImageSquare = 'help__concert_image_square',
  concertStartTime = 'help__concert_start_time',
  concertStreamStartTime = 'help__concert_stream_start_time',
  courtesyCredit = 'help__courtesy_credit',
  cover = 'help__image_cover',
  expandLocalizedInput = 'help__expand_localized_input',
  featuredArtists = 'help__featured_artists',
  featuredGroups = 'help__featured_groups',
  geoAccessCountries = 'help__geo_access_countries',
  geoAccessMode = 'help__geo_access_mode',
  immersiveEnvironment = 'help__image_immersive',
  markdownText = 'help__markdown_text',
  playoutEndTime = 'help__playout_end_time',
  playoutStartTime = 'help__playout_start_time',
  publicationLevel = 'help__publication_level',
  streamUrl = 'help_stream_url',
  takedownDate = 'help__takedown_date',
  teaserImage = 'help__teaser_image',
  teaserImageSafeZone = 'help__teaser_image_safe_zone',
  teaserSquareImage = 'help__teaser_square_image',
  teaserSquareImageSafeZone = 'help__teaser_image_square_safe_zone',
}

export enum FormActionMessages {
  cancel = 'action__cancel',
  create = 'action__create',
  publish = 'action__publish',
  save = 'action__save',
  saveDraft = 'action__save_draft',
}

export enum FormActionFeedback {
  deletedDraft = 'feedback__delete_draft_success',
  published = 'feedback__publish_success',
  publishFailed = 'feedback__publish_failed',
  savedDraft = 'feedback__draft_saved',
  unpublished = 'feedback__unpublish_success',
}
